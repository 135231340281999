body {
	background-color: #d6d1cafa;
	margin: 0;
	padding: 0;
	font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans',
		'Droid Sans', 'Helvetica Neue', sans-serif;
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
	/* height: 100%; */
}
/* 
code {
	font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace;
} */

#root {
	padding: 0;
	margin: 0;
	border: 0;
}

h2 {
	text-align: center;
	margin-top: 15px;
}

.header {
	background: linear-gradient(to right, #6398bb, #ca9c35);
	width: 100%;
}

.container {
	margin-bottom: 70px;
	max-width: 1200px;
}

.navbar a {
	font-weight: bold;
	color: white;
}

.navbar a:hover {
	color: white;
	text-decoration: underline;
	cursor: pointer;
}

.navbar-brand > img {
	display: inline-block;
	width: 80px;
	margin-bottom: 2px;
	margin-right: 5px;
	border-radius: 2px;
}

/* anything that's a ul with a class nav and navbar-nav, inside a li item */
ul.nav.navbar-nav li {
	margin: 0 0.5rem;
}

.navbar {
	background-size: 100% 100%;
}

.home-head > img {
	display: block;
	width: 50vw;
	max-width: 300px;
	border-radius: 5px;
	margin: 1rem auto;
}

.intro {
	margin: 10px auto;
	max-width: 750px;
}

.intro p {
	text-align: justify;
}

.haiyan-bg {
	width: 100vw;
	height: 100vh;
	position: absolute;
	left: 0;
	display: flex;
	flex-direction: column;
	align-items: flex-end;
	justify-content: top;
	z-index: -1;
	background-image: url("./images/HaiyanYang.jpg");
	padding: 1rem;
	background-position: center center;
	background-size: cover;
	text-shadow: 0 0 8px #66757f;
}

.haiyan-bg .btn {
	color: white;
	border-color: #3e7e96;
	background-color: #3e7e96;
}

/* For layout of Suggestions */

.message-box {
	width: 100%;
}

.message-create {
	padding: 10px 10px;
	text-align: center;
}

.message-history {
	max-width: 800px;
	margin: 10px auto;
}

#messages .list-group-item {
	display: grid;
	align-items: flex-start;
	padding: 7px 20px 12px;
}

#messages .list-group-item {
	display: flex;
	align-items: flex-start;
	padding: 7px 20px 12px;
}

#messages:not(.no-hover) .list-group-item:hover {
	background-color: #e6ecf0;
}

.timeline-image {
	border-radius: 50px;
	height: 40pxz;
	width: 40px;
	margin: 2px 30px 10px 10px;
}

.btn:hover {
	cursor: pointer;
}

a.btn.btn-danger,
a.btn.btn-danger:hover {
	color: white;
}

.row .message-form-wrapper {
	margin-top: 20px;
	/* min-height: 200px; */
}

textarea.form-control {
	min-height: 100px;
	margin-bottom: 15px;
}

/* Grid style */
.main-grid {
	width: 90vw;
	max-width: 1200px;
	margin: auto;
	display: grid;
	grid-row-gap: 1em;
	grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
}

/* This grid item is also a category grid */
.main-grid-item {
	background-color: #c3c6c5;
	margin: 1em;
	grid-row-gap: 1em;
	display: grid;
	grid-template-rows: 2em auto;
	border-radius: 5px;
	align-content: start;
	transition: all .8s ease;
}

.category-grid-item-title {
	font-weight: 700;
	width: 30%;
}

.category-grid-item {
	color: #000;
	text-align: left;
	margin: 5px;
}

/* log out section */
.logout {
	display: flex;
	flex-direction: column;
}

/* Footer style */
.footer-container {
	background: linear-gradient(to right, #6398bb, #ca9c35);
	width: 100%;
	position: fixed;
	bottom: 0;
}

.footer {
	width: 230px;
	margin: 15px auto;
	font-size: 1em;
}

/* For the sandwich button on small creens */
.toggle-button {
	display: flex;
	flex-direction: column;
	justify-content: space-between;
	height: 24px;
	width: 30px;
	background: transparent;
	border: none;
	cursor: pointer;
	padding: 0;
	box-sizing: border-box;
}

.toggle-button:focus {
	outline: none;
}

.toggle-button__line {
	width: 30px;
	height: 2px;
	background: white;
}

.backdrop {
	position: fixed;
	width: 100%;
	height: 100%;
	top: 0;
	left: 0;
	background: rgba(0, 0, 0, 0.5);
	z-index: 100;
}

/* sidedrawer */
.side-drawer {
	background: linear-gradient(to bottom, #6398bb, #694f16);
	box-shadow: 2px 0px 5px rgba(0, 0, 0, 0.5);
	position: fixed;
	top: 0;
	right: 0;
	width: 40vmin;
	max-width: 350px;
	z-index: 200;
	-webkit-transform: translateX(200%);
	transform: translateX(200%);
	transition: transform 0.4s ease-in-out;
}

.side-drawer.open {
	transform: translateX(0);
	-webkit-transform: translateX(0);
}

.side-drawer ul {
	height: 100%;
	list-style: none;
	display: flex;
	flex-direction: column;
	justify-content: space-around;
	padding-inline-start: 0;
	margin-block-start: 0;
	margin-block-end: 0;
}

.side-drawer li {
	margin: 0.5rem 0.5rem;
}

.side-drawer a {
	color: #fff;
	text-decoration: none;
	font-size: 1.2rem;
}

.side-drawer a:hover,
.side-drawer a:active {
	color: #fa923f;
}

.top_drawer-toggle-container {
	position: absolute;
	right: 15px;
	top: 15px;
}

@media screen and (min-width: 576px) {
	.side-drawer {
		display: none;
	}

	.top_drawer-toggle-container {
		display: none;
	}
}
